import './Visualization.scss';

import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import { sortBy } from 'lodash';

import DomainBreakdownBar from './DomainBreakdownBar';
import { Framework, Mode } from './types';

interface Props {
  frameworks: Framework[];
  mode: Mode;
  sortedDomainID: string | null;
  expandedDomainID: string | null;
  sortedSubdomainID: string | null;
}

type Domain = {
  count: number;
  identifier: string;
};

type Domains = {
  [identifier: string]: Domain[];
};

function skillsInDomains(domains: Domain[] | null): number {
  if (domains == null) {
    return 0;
  }
  return domains.reduce((total, d) => total + d.count, 0);
}

function domainsAndSubdomains(framework: Framework): {
  count: number;
  identifier: string;
}[] {
  return framework.domains.reduce(
    (all, domain) => [...all, ...domain.subdomains],
    framework.domains as any[]
  );
}

const CompareDomainsVisualization: React.FC<Props> = (props) => {
  const {
    frameworks,
    mode,
    sortedDomainID,
    expandedDomainID,
    sortedSubdomainID
  } = props;

  const sortByID =
    sortedSubdomainID != null ? sortedSubdomainID : sortedDomainID;

  const domains = useMemo(() => {
    return frameworks.reduce((domains, framework) => {
      if (expandedDomainID != null) {
        const domain = framework.domains.find(
          (d) => d.identifier === expandedDomainID
        );
        domains[framework.identifier] = domain ? domain.subdomains : [];
      } else {
        domains[framework.identifier] = framework.domains;
      }
      return domains;
    }, {} as Domains);
  }, [expandedDomainID, frameworks]);

  const sortedFrameworks = useMemo(() => {
    const frameworksWithDomains = frameworks.filter(
      (framework) => framework.domains.length > 0
    );

    if (sortByID == null) {
      if (frameworksWithDomains[0]?.position != null) {
        return sortBy(frameworksWithDomains, (f) => f.position);
      } else {
        return sortBy(frameworksWithDomains, (f) => f.name);
      }
    }

    return sortBy(frameworksWithDomains, (f) => {
      const domain = domainsAndSubdomains(f).find(
        (d) => d.identifier === sortByID
      );

      // TODO: This can be simplified after upgrading to Typescript 3.7
      let domains: Domain[] = f.domains;

      if (expandedDomainID) {
        const domain = f.domains.find((d) => d.identifier === expandedDomainID);

        domains = domain ? domain.subdomains : [];
      }

      return -(
        (domain ? domain.count : 0) /
        (mode === 'percent' ? skillsInDomains(domains) : 1)
      );
    });
  }, [frameworks, mode, sortByID, expandedDomainID]);

  const mostSkillsInFramework = useMemo(() => {
    const skillCounts = Object.keys(domains).map((frameworkID) => {
      return domains[frameworkID].reduce(
        (total, domain) => total + domain.count,
        0
      );
    });

    return Math.max(...skillCounts);
  }, [domains]);

  return (
    <div className="CompareDomainsVisualization" aria-live="polite">
      {sortedFrameworks.map((framework) => (
        <div
          key={framework.identifier}
          className="CompareDomainsVisualization-row"
        >
          <Link
            to={`/frameworks/${framework.identifier}/`}
            className="CompareDomainsVisualization-frameworkLabel"
          >
            {framework.name}
          </Link>

          <DomainBreakdownBar
            domains={domains[framework.identifier]}
            totalSkills={mode === 'count' ? mostSkillsInFramework : null}
            showLabel={mode === 'percent'}
            emphasizedDomainID={sortByID}
          />
        </div>
      ))}
    </div>
  );
};

export default CompareDomainsVisualization;
