import './CompareDomains.scss';

import React from 'react';

import { usePersistentState } from 'utils/usePersistentState';
import Controls from './Controls';
import Visualization from './Visualization';
import { Framework, Mode } from './types';

import FrameworkFilters from 'components/framework-filters/FrameworkFilters';
import { useFrameworkFilters } from 'components/framework-filters/useFrameworkFilters';

interface Props {
  frameworks: Framework[];
}

const CompareDomains: React.FC<Props> = (props) => {
  const { frameworks } = props;

  const [mode, setMode] = usePersistentState<Mode>(
    'compare-domains-mode',
    'percent'
  );
  const [focusedDomainID, setFocusedDomainID] = usePersistentState<
    string | null
  >('compare-domains-focusedDomainID', null);
  const [sorted, setSorted] = usePersistentState(
    'compare-domains-sorted',
    false
  );
  const [expandSubdomains, setExpandSubdomains] = usePersistentState(
    'compare-domains-expandSubdomains',
    false
  );
  const [sortedSubdomainID, setSortedSubdomainID] = usePersistentState<
    string | null
  >('compare-domains-sortedSubdomainID', null);

  const filters = useFrameworkFilters(frameworks);

  return (
    <div className="CompareDomains">
      <div className="CompareDomains-frameworkFilters">
        <FrameworkFilters filters={filters} />
      </div>
      <Controls
        mode={mode}
        setMode={setMode}
        focusedDomainID={focusedDomainID}
        setFocusedDomainID={setFocusedDomainID}
        sorted={sorted}
        setSorted={setSorted}
        expandSubdomains={expandSubdomains}
        setExpandSubdomains={setExpandSubdomains}
        sortedSubdomainID={sortedSubdomainID}
        setSortedSubdomainID={setSortedSubdomainID}
      />

      {filters.frameworks.length === 0 && (
        <div className="CompareDomains-empty">
          No Frameworks Matching Current Filters{' '}
          <button
            type="button"
            className="CompareDomains-resetFilters"
            onClick={filters.reset}
          >
            Clear Filters
          </button>
        </div>
      )}

      <Visualization
        frameworks={filters.frameworks}
        mode={mode}
        sortedDomainID={sorted ? focusedDomainID : null}
        expandedDomainID={expandSubdomains ? focusedDomainID : null}
        sortedSubdomainID={sorted ? sortedSubdomainID : null}
      />
    </div>
  );
};

export default CompareDomains;
