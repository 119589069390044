import './Controls.scss';

import React from 'react';

import useDomainDescriptions from 'data/hooks/useDomainDescriptions';
import * as Core from 'components/core';

import ModeButton from './ModeButton';
import LegendItem from './LegendItem';

interface Props {
  mode: 'percent' | 'count';
  setMode(mode: 'percent' | 'count'): void;
  focusedDomainID: string | null;
  setFocusedDomainID(id: string | null): void;
  sorted: boolean;
  setSorted(sort: boolean): void;
  expandSubdomains: boolean;
  setExpandSubdomains(expand: boolean): void;
  sortedSubdomainID: string | null;
  setSortedSubdomainID(id: string | null): void;
}

const CompareDomainsControls: React.FC<Props> = (props) => {
  const {
    mode,
    setMode,
    focusedDomainID,
    setFocusedDomainID,
    sorted,
    setSorted,
    expandSubdomains,
    setExpandSubdomains,
    sortedSubdomainID,
    setSortedSubdomainID
  } = props;

  const domainDescriptions = useDomainDescriptions();

  const focusedDomain =
    focusedDomainID != null
      ? domainDescriptions.find((d) => d.identifier === focusedDomainID)
      : null;

  const toggleFocusedDomain = (id: string) => {
    if (id === focusedDomainID) {
      setFocusedDomainID(null);
    } else {
      setFocusedDomainID(id);
      if (!sorted && !expandSubdomains) {
        setSorted(true);
      }
    }
    setSortedSubdomainID(null);
  };

  const toggleSortedSubdomain = (id: string) => {
    if (id === sortedSubdomainID) {
      setSortedSubdomainID(null);
    } else {
      setSortedSubdomainID(id);
    }
  };

  const toggleSorted = () => {
    setSorted(!sorted);
  };

  const toggleExpandedSubdomains = () => {
    setExpandSubdomains(!expandSubdomains);
    setSortedSubdomainID(null);
  };

  return (
    <div className="CompareDomainsControls">
      <div className="no-print">
        <h4>View Options</h4>
        <div className="CompareDomainsControls-buttons">
          <ModeButton
            onClick={() => setMode('percent')}
            selected={mode === 'percent'}
            title="General Focus: Relative Values"
            description="This view shows how much emphasis each framework places on one domain relative to the other domains. This graph is most useful for understanding a framework’s general focus, or which domains receive the most attention in a particular framework."
          />
          <ModeButton
            onClick={() => setMode('count')}
            selected={mode === 'count'}
            title="Depth of Focus: Absolute Values"
            description="This view shows the level of detail with which each framework covers a particular domain by taking into consideration the size and scope of the framework – the total number of skills that fall under each domain and the level of detail with which they are defined. This graph is most useful for understanding the depth or comprehensiveness of a framework’s focus on a particular domain."
          />
        </div>
      </div>

      <div className="CompareDomainsControls-domainsHeading">
        <h4>Domains</h4>

        {focusedDomain && (
          <div className="no-print">
            <Core.SmallButton selected={sorted} onClick={toggleSorted}>
              <span className="CompareDomainsControls-optionCheck">
                {sorted ? '☑' : '☐'}
              </span>{' '}
              Sort by Domain
            </Core.SmallButton>

            <Core.SmallButton
              selected={expandSubdomains}
              onClick={toggleExpandedSubdomains}
            >
              <span className="CompareDomainsControls-optionCheck">
                {expandSubdomains ? '☑' : '☐'}
              </span>{' '}
              Show Subdomains
            </Core.SmallButton>

            <button
              onClick={() => {
                setFocusedDomainID(null);
                setExpandSubdomains(false);
              }}
              className="CompareDomainsControls-resetButton"
            >
              Reset
            </button>
          </div>
        )}
      </div>

      <div className="CompareDomainsControls-domains">
        {domainDescriptions.map((domain) => (
          <LegendItem
            key={domain.identifier}
            domain={domain}
            selected={focusedDomainID === domain.identifier}
            disabled={
              focusedDomainID != null &&
              expandSubdomains &&
              domain.identifier !== focusedDomainID
            }
            onClick={() => toggleFocusedDomain(domain.identifier)}
          />
        ))}
      </div>

      {focusedDomain != null && expandSubdomains && (
        <div className="CompareDomainsControls-domains">
          {focusedDomain.subdomains.map((domain) => (
            <LegendItem
              key={domain.identifier}
              domain={domain}
              selected={sortedSubdomainID === domain.identifier}
              onClick={() => toggleSortedSubdomain(domain.identifier)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CompareDomainsControls;
