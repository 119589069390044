import './LegendItem.scss';

import React from 'react';
import classnames from 'classnames';

import PrintBackground from 'components/core/PrintBackground';

interface Props {
  domain: {
    name: string;
    identifier: string;
    color: string;
    description: string;
  };
  selected: boolean;
  disabled?: boolean;
  onClick(event: React.SyntheticEvent<HTMLElement>): void;
}

const CompareDomainsLegendItem: React.FC<Props> = (props) => {
  const { domain, selected, disabled, onClick } = props;

  return (
    <div
      className={classnames('CompareDomainsLegendItem', {
        'CompareDomainsLegendItem--disabled': disabled
      })}
      onClick={onClick}
      style={
        selected
          ? {
              borderColor: domain.color,
              backgroundColor: `${domain.color}30`
            }
          : {}
      }
    >
      <div
        className="CompareDomainsLegendItem-icon"
        style={{
          backgroundColor: domain.color
        }}
      >
        <PrintBackground color={domain.color} />
      </div>

      {domain.name}
    </div>
  );
};

CompareDomainsLegendItem.defaultProps = {
  disabled: false
};

export default CompareDomainsLegendItem;
