import React, { useMemo } from 'react';
import { sortBy } from 'lodash';

import { useAllDomainDescriptions } from 'data/hooks/useDomainDescriptions';

import SingleStackedBar from 'components/visualization/single-stacked-bar/SingleStackedBar';

interface Props {
  domains: {
    count: number;
    identifier: string;
  }[];
  totalSkills: number | null;
  showLabel: boolean;
  emphasizedDomainID: string | null;
}

const DomainBreakdownBar: React.FC<Props> = (props) => {
  const { domains, totalSkills, showLabel, emphasizedDomainID } = props;

  const domainDescriptions = useAllDomainDescriptions();

  const sortedDomains = useMemo(
    () =>
      sortBy(domains, (domain) =>
        domain.identifier === emphasizedDomainID
          ? 0
          : parseInt(domain.identifier)
      ),
    [domains, emphasizedDomainID]
  );

  const items = useMemo(() => {
    return sortedDomains.map((domain) => {
      const description = domainDescriptions.find(
        (description) => description.identifier === domain.identifier
      );

      return {
        id: domain.identifier,
        value: domain.count,
        color: description?.color || '#000000',
        label: description?.name
      };
    });
  }, [domainDescriptions, sortedDomains]);

  return (
    <SingleStackedBar
      items={items}
      total={totalSkills}
      showPercentLabels={showLabel}
    />
  );
};

DomainBreakdownBar.defaultProps = {
  showLabel: true
};

export default DomainBreakdownBar;
