import './ModeButton.scss';

import React from 'react';
import classnames from 'classnames';

interface Props {
  onClick: () => void;
  selected: boolean;
  title: string;
  description: string;
}

const ModeButton: React.FC<Props> = (props) => {
  const { onClick, selected, title, description } = props;

  return (
    <div>
      <button
        onClick={onClick}
        className={classnames('ModeButton-button', {
          'ModeButton-button--selected': selected
        })}
      >
        <div className="ModeButton-button-label">
          <div className="ModeButton-button-radio-input" />
          <div className="ModeButton-button-label-text">{title}</div>
        </div>
      </button>
      <p className="ModeButton-text">{description}</p>
    </div>
  );
};

export default ModeButton;
