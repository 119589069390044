import './DomainDefinitions.scss';

import React from 'react';

import useDomainDescriptions from 'data/hooks/useDomainDescriptions';

import * as Core from 'components/core';

const CompareDomainsDomainDefinitions: React.FC = () => {
  const domainDescriptions = useDomainDescriptions();

  return (
    <div className="CompareDomainsDefinitions">
      {domainDescriptions.map((domain) => (
        <div
          className="CompareDomainsDefinitions-definition"
          key={domain.identifier}
        >
          <div className="CompareDomainsDefinitions-definition-title">
            <div
              className="CompareDomainsDefinitions-definition-color"
              style={{
                backgroundColor: domain.color
              }}
            >
              <Core.PrintBackground color={domain.color} />
            </div>
            <h3>{domain.name}</h3>
          </div>
          <Core.Markdown>{domain.description}</Core.Markdown>
        </div>
      ))}
    </div>
  );
};

export default CompareDomainsDomainDefinitions;
