import './compare-domains.scss';

import React, { useMemo } from 'react';
import { graphql, Link } from 'gatsby';

import Layout from 'components/layout/Layout';
import VisualToolLinks from 'components/VisualToolLinks';
import CompareDomainsVisualization from 'components/compare-domains/CompareDomains';
import DomainDefinitions from 'components/compare-domains/DomainDefinitions';

interface Props {
  data: {
    allFrameworks: {
      frameworks: {
        identifier: number;
        group: number;
        name: string;
        position: number | null;
        filterAttributes: { [key: string]: string[] };
      }[];
    };
    allFrameworkDomainEmphasisJson: {
      frameworkDomains: {
        identifier: number;
        domains: {
          count: number;
          identifier: string;
          subdomains: {
            identifier: string;
            count: number;
          }[];
        }[];
      }[];
    };
  };
}

const CompareDomainsPage: React.FC<Props> = (props) => {
  const { frameworkDomains } = props.data.allFrameworkDomainEmphasisJson;

  const frameworkAttributes = useMemo(() => {
    return props.data.allFrameworks.frameworks.filter(
      // exclude 'sub frameworks' which have group different than their id
      (f) => f.identifier === f.group
    );
  }, [props.data]);

  const frameworks = useMemo(() => {
    return frameworkAttributes.map((framework) => {
      const domains = frameworkDomains.find(
        (d) => d.identifier === framework.identifier
      ) || {
        domains: []
      };
      return {
        ...framework,
        ...domains
      };
    });
  }, [frameworkAttributes, frameworkDomains]);

  return (
    <Layout>
      <div className="CompareDomainsPage-intro">
        <h1>Compare Skill Focus Across Frameworks</h1>
        <p className="emphasis">
          Frameworks focus on different things – some cover many areas of SEL
          and the non-academic domain while others take a more focused approach.
          This can make it difficult to determine which frameworks meet your
          needs or identify those that can be paired to create alignment between
          ages and across settings.
        </p>
        <p className="emphasis">
          Use the bar graphs below to compare the extent to which each framework
          concentrates on six common areas, or domains, of SEL.
        </p>
      </div>

      <CompareDomainsVisualization frameworks={frameworks} />

      <p>
        <Link to="/faq/#included-frameworks">
          How do we decide which frameworks to include?
        </Link>
      </p>

      <p>
        <Link to="/faq/#categorizing-terms-by-domain">
          How did you identify which domains are targeted by each term?
        </Link>
      </p>
      <div className="CompareDomainsPage-definitions" id="definitions">
        <h2>Definition of Domains</h2>
        <DomainDefinitions />

        <p>
          <Link to="/faq/#sel-domains">
            What is included in each SEL Domain?
          </Link>
        </p>
      </div>

      <VisualToolLinks excludeVisualToolLink="domains" />
    </Layout>
  );
};

export const query = graphql`
  query CompareDomainsQuery {
    allFrameworks: allFrameworksJson {
      frameworks: nodes {
        identifier
        group
        name
        position
        # filterAttributes {
        #   developerType
        #   documentType
        #   focus
        #   ageRange
        #   language
        #   setting
        #   regionOfOrigin
        #   countryOfOrigin
        #   regionOfUse
        #   countryOfUse
        #   countryOfOriginIncomeLevel
        #   countryOfUseIncomeLevel
        # }
      }
    }
    allFrameworkDomainEmphasisJson {
      frameworkDomains: nodes {
        identifier
        domains {
          count
          identifier
          subdomains {
            count
            identifier
          }
        }
      }
    }
  }
`;

export default CompareDomainsPage;
